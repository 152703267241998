import React from "react";
import "./CSS/homePage.css";
import FooterComponent from "./footer";
import { Link, useNavigate } from "react-router-dom";
import videolink from "../src/VIDEO/REVX_HYPE_VIDEO_v3.mp4";
import secondVideoLink from "../src/VIDEO/xarvio_CommClassic_Video_v2.mp4";
import Header from "./header";
import Layout from "./Layout";
import { ReactComponent as InteractIcon } from "../src/interact-icon.svg";
import { ReactComponent as PlayIcon } from "../src/play_video.svg";
import bgImage from "../src/v02_BASF_CommClassicVideos_ambientField_JS.gif";

const Homepage = () => {
  const loopingBg = require("../src/v02_BASF_CommClassicVideos_ambientField_JS.gif");

  const growAdvantageIframeLink = "https://www.growsmartlive.com/gsat";

  const revXIframeLink = "https://www.revxfield.com/fungicide-trial-map";

  const navigate = useNavigate();

  const goToGrowAdvantageIframe = () => {
    navigate(
      "/tools",
      { state: { iframeLink: growAdvantageIframeLink } },
      { replace: true }
    );
  };

  const gotoRevxIframe = () => {
    navigate(
      "/tools",
      { state: { iframeLink: revXIframeLink } },
      { replace: true }
    );
  };

  const goToFirstVideo = () => {
    navigate("/play-video", { state: { videolink: secondVideoLink } });
  };

  const goToSecondVideo = () => {
    navigate("/play-video", { state: { videolink: videolink } });
  };

  return (
    <Layout
      showHeader={false}
      slug={"home"}
      animatedBg={[" bg-enabled", loopingBg]}
    >
      <div className="container">
        <div className="row">
          <div className="column">
            <h2>Explore</h2>
            <Link
              to={growAdvantageIframeLink}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="nav-tile">
                <div className="nav-tile-icon interact">
                  <InteractIcon />
                </div>
                <div className="nav-tile-title">
                  <p>
                    Grow Smart
                    <br />
                    Advantage
                  </p>
                </div>
              </div>
            </Link>
            <div className="nav-tile" onClick={gotoRevxIframe}>
              <div className="nav-tile-icon interact">
                <InteractIcon />
              </div>
              <div className="nav-tile-title">
                <p>RevXFields</p>
              </div>
            </div>
          </div>
          <div className="column">
            <h2>Learn More</h2>

            <div className="nav-tile" onClick={goToFirstVideo}>
              <div className="nav-tile-icon play first">
                <PlayIcon />
              </div>
              <div className="nav-tile-title">
                <p>Watch to learn about xarvio&reg; Digital Farming Solution</p>
              </div>
            </div>
            <div className="nav-tile" onClick={goToSecondVideo}>
              <div className="nav-tile-icon play second">
                <PlayIcon />
              </div>
              <div className="nav-tile-title">
                <p>Watch to learn about RevXFields</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Homepage;
